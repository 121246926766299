/* eslint-disable no-unused-vars */
import React from 'react';
import classnames from 'classnames';
import repWorkData from '../../utils/mock/repWorkData';
import forStorybook from '../../utils/forStorybook.jsx';
import { SizeEnum } from '../../utils/enums.js';
import { withTranslation } from 'react-i18next';
import { Button } from '../Button/Button.js';
import CheckboxWrapper from '../Checkbox/CheckboxWrapper.jsx';
import { ChevronDown, ChevronUp, ChevronLeft, ChevronRight } from '../../assets/LinkIcons.js';
import './representative-work-filter.scss';

const RepresentativeWorkFilter = ({
    translation,
    fields,
    index,
    activeFilter,
    toggleFilter,
    checkBox,
    uncheckAllBoxes,
    onApplyFilters,
    disabledState,
    setDisabledState,
}) => {
    const representativeWorkFilterBlock = 'zn-representative-work-filter';
    const { label, options, totalSelected, parentId } = fields;

    const selectableFilters = options.filter((item) => item.hasResults).length;

    return (
        <div
            className={classnames(
                `${representativeWorkFilterBlock}`,
                {
                    [`${representativeWorkFilterBlock}--active`]: index === activeFilter,
                },
                {
                    [`${representativeWorkFilterBlock}--mobile-open`]: activeFilter === null,
                }
            )}
        >
            <button
                className={classnames(`${representativeWorkFilterBlock}__header`, {
                    [`${representativeWorkFilterBlock}__header--active`]:
                        index === activeFilter && !disabledState,

                    [`${representativeWorkFilterBlock}__header--disabled`]: disabledState,
                })}
                onClick={() => {
                    if (!disabledState) toggleFilter(index);
                }}
            >
                <div
                    className={classnames(`${representativeWorkFilterBlock}__title`, {
                        [`${representativeWorkFilterBlock}__title--active`]:
                            index === activeFilter && !disabledState,
                        [`${representativeWorkFilterBlock}__title--disabled`]: disabledState,
                    })}
                >
                    {label}
                    <div className={`${representativeWorkFilterBlock}__desktop-selected-filters`}>
                        {totalSelected > 0 ? ` (${totalSelected})` : ''}
                    </div>
                </div>
                <div
                    className={classnames(`${representativeWorkFilterBlock}__desktop-chevron`, {
                        [`${representativeWorkFilterBlock}__desktop-chevron--disabled`]: disabledState,
                    })}
                >
                    {index === activeFilter ? (
                        <ChevronUp size={SizeEnum.small} />
                    ) : (
                        <ChevronDown size={SizeEnum.small} />
                    )}
                </div>
                <div
                    className={classnames(`${representativeWorkFilterBlock}__mobile-chevron`, {
                        [`${representativeWorkFilterBlock}__mobile-chevron--active`]:
                            index === activeFilter,
                    })}
                >
                    {index === activeFilter ? (
                        <ChevronLeft size={SizeEnum.small} />
                    ) : (
                        <ChevronRight size={SizeEnum.small} />
                    )}
                </div>
            </button>
            <div
                className={classnames(`${representativeWorkFilterBlock}__mobile-divider`, {
                    [`${representativeWorkFilterBlock}__mobile-divider--filter-open`]:
                        activeFilter !== null,
                })}
            ></div>
            <div
                className={classnames(`${representativeWorkFilterBlock}__mobile-selected-filters`, {
                    [`${representativeWorkFilterBlock}__mobile-selected-filters--filter-open`]:
                        activeFilter !== null,
                })}
            >
                {totalSelected > 0 && `${totalSelected} ${translation('FiltersSelected')}`}
            </div>
            <ul
                className={classnames(`${representativeWorkFilterBlock}__options-list`, {
                    [`${representativeWorkFilterBlock}__options-list--active`]:
                        index === activeFilter,
                })}
            >
                {selectableFilters ? (
                    options.map((option, index) => {
                        if (!option.hasResults) return; //if no facetted results do not show
                        return (
                            <CheckboxWrapper
                                checked={option.checked}
                                change={checkBox}
                                key={index}
                                id={option.id}
                                pId={parentId}
                                label={option.label}
                                disabledState={disabledState}
                                setDisabledState={setDisabledState}
                            />
                        );
                    })
                ) : (
                    <li className={`${representativeWorkFilterBlock}__no-filters`}>
                        <div
                            className={classnames(
                                `${representativeWorkFilterBlock}__no-filters-title`,
                                {
                                    [`${representativeWorkFilterBlock}__no-filters-title--disabled`]: disabledState,
                                }
                            )}
                        >
                            {translation('NoMatchingFiltersTitle')}
                        </div>
                        <div
                            className={classnames(
                                `${representativeWorkFilterBlock}__no-filters-description`,
                                {
                                    [`${representativeWorkFilterBlock}__no-filters-description--disabled`]: disabledState,
                                }
                            )}
                        >
                            {translation('NoMatchingFiltersDescription')}
                        </div>
                    </li>
                )}
            </ul>
            <div
                className={classnames(`${representativeWorkFilterBlock}__options-footer`, {
                    [`${representativeWorkFilterBlock}__options-footer--active`]:
                        index === activeFilter,
                })}
            >
                <button
                    className={`${representativeWorkFilterBlock}__clear-button`}
                    onClick={() => {
                        uncheckAllBoxes(parentId);
                    }}
                >
                    {translation('ResetFilter')}
                </button>
                <div className={`${representativeWorkFilterBlock}__apply-button`}>
                    <Button className="zn-main-nav-bar--hide-mobile" onClick={onApplyFilters}>
                        {translation('ShowResults')}
                    </Button>
                </div>
            </div>
            <div className={`${representativeWorkFilterBlock}__divider`}></div>
        </div>
    );
};

RepresentativeWorkFilter.defaultProps = repWorkData;

export const RepresentativeWorkFilterStorybook = forStorybook(RepresentativeWorkFilter);

const RepresentativeWorkWithTranslation = withTranslation()(RepresentativeWorkFilter);

export default RepresentativeWorkWithTranslation;
