import React, { useEffect, useState } from 'react';
import { Placeholder, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import createPageTitle from './utils/pageTitleGenerator';
import { withTranslation } from 'react-i18next';
import Helmet from 'react-helmet';

// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
import 'bootstrap/dist/css/bootstrap.css';
import './assets/app.css';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

export const MAIN_CONTENT_PRE = 'main-content-pre';
const Layout = ({ route, t, lang }) => {
    const pageTitle = createPageTitle(route, t);
    const [animationStyle, setAnimationStyle] = useState('');
    const [isPopEvent, setIsPopEvent] = useState(false);
    const MetaDescriptionIncluded = route?.fields.MetaDescription?.value;
    const TeaserDescriptionIncluded =
        !route?.fields.MetaDescription?.value && route?.fields.TeaserDescription?.value;
    const MetaImageIncluded = route?.fields.MetaImage?.value?.src;
    const TeaserImageIncluded =
        !route?.fields.MetaImage?.value?.src && route?.fields.TeaserImage?.value?.src;

    useEffect(() => {
        if (
            window?.location?.href?.includes('pdf_view=1') ||
            window?.location?.href?.includes('pdf_view%3D1')
        ) {
            setAnimationStyle('no-animation is-pdf-view');
        }
    }, []);

    useEffect(() => {
        if (!isPopEvent) {
            //load to top of page on new page navigation
            const html = document.querySelector('html');
            html.style.scrollBehavior = 'auto';

            setTimeout(function () {
                window.scrollTo(0, 0);
                html.style.scrollBehavior = 'smooth';
            });
        } else {
            //restore scroll position
            const html = document.querySelector('html');
            html.style.scrollBehavior = 'auto';
            const state = window.history.state;

            setTimeout(function () {
                const pageScrollValue = state.__scrollY || 0; //holds the session variable of the current page
                window.scrollTo(0, pageScrollValue);
                html.style.scrollBehavior = 'smooth';
            }, 300);
        }
        setIsPopEvent(false);
    }, [route]);

    useEffect(() => {
        window.history.scrollRestoration = 'manual'; //disable browser scroll restoration

        let originalPushState = window.history.pushState;
        let originalReplaceState = window.history.replaceState;

        // Overriding native history pushState function, stores current scroll position in current state when navigating away.
        window.history.pushState = function () {
            let newStateOfCurrentPage = Object.assign({}, window.history.state, {
                __scrollX: window.scrollX,
                __scrollY: window.scrollY,
            });
            originalReplaceState.call(window.history, newStateOfCurrentPage, ''); //replace state of current page overrding the native replace state function

            originalPushState.apply(window.history, arguments);
        };

        // Overriding native history replace State, makes sure we don't throw away scroll position when calling "replaceState".
        window.history.replaceState = function (state) {
            let newState = Object.assign(
                {},
                {
                    __scrollX: window.history.state && window.history.state.__scrollX,
                    __scrollY: window.history.state && window.history.state.__scrollY,
                },
                state
            );

            for (
                var _len = arguments.length, otherArgs = Array(_len > 1 ? _len - 1 : 0), _key = 1;
                _key < _len;
                _key++
            ) {
                otherArgs[_key - 1] = arguments[_key];
            }

            originalReplaceState.apply(window.history, [newState].concat(otherArgs));
        };
        window.onpopstate = () =>
            setTimeout(() => {
                setIsPopEvent(true);
            }, 0);
    }, []);

    useEffect(() => {
        // Set the lang attribute on the <html> element whenever language changes
        // After the language is updated, trigger OneTrust's re-localization
        if (window.OneTrust) {
            window.OneTrust.changeLanguage(lang);
        }
    }, [lang]);

    return (
        <React.Fragment>
            {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
            <Helmet htmlAttributes={{ lang }}>
                <title>{pageTitle}</title>
                {pageTitle && <meta property="og:title" content={pageTitle} />}
                {MetaDescriptionIncluded && (
                    <meta name="description" content={route?.fields.MetaDescription?.value} />
                )}
                {MetaDescriptionIncluded && (
                    <meta
                        property="og:description"
                        content={route?.fields.MetaDescription?.value}
                    />
                )}
                {!MetaDescriptionIncluded && TeaserDescriptionIncluded && (
                    <meta name="description" content={route?.fields.TeaserDescription?.value} />
                )}
                {!MetaDescriptionIncluded && TeaserDescriptionIncluded && (
                    <meta
                        property="og:description"
                        content={route?.fields.TeaserDescription?.value}
                    />
                )}
                {MetaImageIncluded && (
                    <meta name="image" content={route?.fields.MetaImage?.value?.src} />
                )}
                {MetaImageIncluded && (
                    <meta property="og:image" content={route?.fields.MetaImage?.value?.src} />
                )}
                {!MetaImageIncluded && TeaserImageIncluded && (
                    <meta name="image" content={route?.fields.TeaserImage?.src} />
                )}
                {!MetaImageIncluded && TeaserImageIncluded && (
                    <meta property="og:image" content={route?.fields.TeaserImage?.value?.src} />
                )}
                {route?.fields.MetaKeywords?.value && (
                    <meta name="keywords" content={route?.fields.TeaserDescription?.value} />
                )}
                <meta property="og:type" content="website" />
                {(!route?.fields?.ExternallySearchable?.value ||
                    route?.fields?.BioPageOnly?.value) && <meta name="robots" content="noindex" />}
                <meta name="theme-color" media="(prefers-color-scheme: light)" content="#f8f9fa" />
                <meta name="theme-color" media="(prefers-color-scheme: dark)" content="#002b51" />
            </Helmet>
            {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
            <VisitorIdentification />
            <div>
                {/* root placeholder for the app, which we add components to using route data */}
                <div className={animationStyle}>
                    <Placeholder name="jss-nav" rendering={route} />
                </div>
                <div className={animationStyle} id={MAIN_CONTENT_PRE}>
                    <Placeholder name="jss-main-pre" rendering={route} />
                </div>
                <div id="zn-main-section" className={animationStyle}>
                    <Placeholder name="jss-main" rendering={route} />
                </div>
                <div className={animationStyle}>
                    <Placeholder name="jss-main-post" rendering={route} />
                </div>
                <div className={animationStyle}>
                    <Placeholder name="jss-footer" rendering={route} />
                </div>
            </div>
        </React.Fragment>
    );
};

const LayoutContext = withTranslation()(Layout);

const App = (props) => <LayoutContext {...props} />;

export default App;
